<template>
  <v-container class="container__space__footer">
    <v-row>
      <v-col>
        <span class="announcement__header">ประกาศจาก admin</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="({ title, description, createdAt }, n) in announcements"
        :key="`announcement-${n}`"
        class="d-flex flex-column"
        cols="12"
      >
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex flex-column mb-4">
            <span class="announcement__message__header">{{ title }}</span>
            <span class="mt-2" style="white-space: pre">{{ description }}</span>
          </div>
          <v-spacer />
          <div>
            <span class="announcement__message__date">{{ getMessageDate(createdAt) }}</span>
          </div>
        </div>
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_ANNOUNCEMENT } from '@/store/_actionTypes';
import { formatDate } from "@/helpers/date";

export default {
  name: 'Announcement',
  created() {
    this[GET_ANNOUNCEMENT]({ accessToken: this.accessToken });
  },
  computed: {
    ...mapState('announcement', [ 'announcements' ]),
    ...mapState('user', [ 'accessToken' ]),
  },
  methods:{
    ...mapActions('announcement', [ GET_ANNOUNCEMENT ]),
    getMessageDate(date){
      return formatDate(date)
    }
  },
};
</script>

<style lang="scss">
  .announcement__header  {
    font-family: Kanit;
    font-size: 20px;
    font-style: normal;
    line-height: 1.5;
    color: #242424;
  }

  .announcement__message__header {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #242424;
  }

  .announcement__message__date {
    font-family: Sarabun;
    font-size: 14px;
    line-height: 1.57;
    color: #999999;
  }

  .container__space__footer {
    margin-bottom: 150px;
  }
</style>